<template>
  <div class="" style="width: 100%;">
    <b-navbar variant="faded" type="light">
      <b-navbar-brand href="#"><img style="max-width:100px;" :src="require('assets/img/logo.png')" /></b-navbar-brand>
    </b-navbar>
    <b-overlay
      id="overlay-background"
      :show="!hasLoaded"
      fixed
      no-wrap
      variant="light"
      opacity="0.85"
      blur="5px"
    >
    </b-overlay>
    <div class="container" v-if="hasLoaded">
      <h1>Caricamento Elementi</h1>
      <div class="row">
        <p>Benvenuto, {{ company.first_name + " " + company.last_name }}. Carica i file rischiesti per <u>{{ company.ragione_sociale }}</u></p>
      </div>
      <!-- <b-card class="text-center">
        ciao
      </b-card> -->
      <div>
        <div v-if="computed_items.length === 0">
          <h3>Hai caricato tutti gli elementi richiesti!</h3>
        </div>
        <b-tabs content-class="mt-3">
          <b-tab v-for="(item, index) in computed_items" :key="index" :title="item.label" :active="index === 0 ? true : false">
            <UploadFile :item="item" :token="token" v-if="item.type === 'file'" :ref="'file_'+index" />
            <UploadString :item="item" :token="token" v-if="item.type === 'string'" :ref="'string_'+index" />            
          </b-tab>
        </b-tabs>
      </div>
      <div class="row custom_error" v-if="error">
        <p style="color:red;">{{ error }} </p>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavbar from '../../Layout/TopNavbar.vue'
import ReminderService from 'src/services/ReminderService'
import Vue from 'vue'
import { NavbarPlugin } from 'bootstrap-vue'
import { TabsPlugin } from 'bootstrap-vue'
Vue.use(TabsPlugin)
Vue.use(NavbarPlugin)

import UploadFile from './UploadFile'
import UploadString from './UploadString'

export default {
  components: {
    TopNavbar,
    UploadFile,
    UploadString
  },
  data() {
    return {
      hasLoaded: false,
      error: null,
      token: null,
      reminder: null,
      data: null,
      items: null,
      company: null      
    }
  },
  created() {
    this.$eventHub.$on('upload-reminder', (reminder) => {
      this.data = reminder.data
      this.items = reminder.template_in_progress.items
      this.company = reminder.company
    })
  },
  beforeDestroy() {
    this.$eventHub.$off('upload-reminder')
  },
  computed: {
    computed_items: function() {
      let items = []
      Object.keys(this.data).forEach(order => {
        if (!this.data[order]) {
          var item = this.items.filter(el => el.order.toString() === order)[0]
          items.push(item)
        }
      })
      return items
    }
  },
  async mounted() {
    try {
      this.token = this.$route.params.token
      if (!this.token) {
        this.$router.push({
          path: '/not-found'
        })
      } else {
        const reminder = await ReminderService.getCompanyReminder(this.token)
        console.log('reminder', reminder)
        this.data = reminder.data.data
        this.items = reminder.data.template_in_progress.items
        this.company = reminder.data.company
      }
    } catch(err) {
      console.log('err', err)
      this.error = 'Errore durante il caricamento degli elementi'
      setTimeout(() => {
        this.$router.push({
          path: '/not-found'
        })
      }, 1)
    } finally {
      this.hasLoaded = true
    }
  }  
}
</script>

<style>

</style>