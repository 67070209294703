<template>
  <div>    
     <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <p><b>Istruzioni: </b>{{ item.instructions }}</p>
    <fg-input :label="item.label"
              type="text"
              :placeholder="'Inserisci ' + item.label"
              v-model="string">
    </fg-input>
    <fg-input label="">
        <div>
          <l-checkbox v-model="nonRilevante">Non rilevante</l-checkbox>
        </div>
    </fg-input>
    <div style="padding:20px;"></div>
    <div class="row custom_error" v-if="error">
      <p style="color:red;">{{ error }} </p>
    </div>
    <div class="row custom_success" v-if="success">
      <p style="color:green;">{{ item.label }} caricato con successo!</p>
    </div>
    <l-button type="primary" style="margin-top: 25px;" :disabled="!string" wide @click="upload_item()">Carica Elemento</l-button>&nbsp;
    <l-button type="primary" style="margin-top: 25px;" wide :class="{ 'active': isActive, 'no-active':!isActive }" @click="saveNonRilevante()">Applica</l-button>&nbsp;
    
  </div>
</template>

<script>
import ReminderService from 'src/services/ReminderService'
import {
    Checkbox as LCheckbox,
    FormGroupInput as FgInput
  } from 'src/components/index'

export default {
  props: ['item', 'token'],
  data() {
    return {
      hasLoaded: true,
      error: null,
      success: false,
      string: "",
      nonRilevante:false,
      isActive:false
    }
  },
  components: {
    LCheckbox
  },
  watch:{
    nonRilevante: function(value){
      this.isActive = value
    }
  },
  methods: {
    async saveNonRilevante(){
        var ris = null
        try{
        const data = {
          "order": this.item.order 
        }
        ris = await ReminderService.uploadItemsNonRilevante(data, this.token)
        } catch(err) {
        console.log('err', err)
        this.error = 'Errore durante l\'aggiornamtento di ' + this.item.label
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.success = false
          this.error = null
          this.$eventHub.$emit('upload-reminder', ris.data)
        }, 2500)
      }
    },     
    async upload_item() {
      var ris = null
      this.hasLoaded = false
      try {
        let objToSend = {
          [this.item.order] : this.string
        }
        
        ris = await ReminderService.uploadItems(objToSend, this.token)
        console.log('ris', ris)
        this.string = ""
        this.success = true
      } catch(err) {
        console.log('err', err)
        this.error = 'Errore durante il caricamento di '+ this.item.label
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.success = false
          this.error = null
          this.$eventHub.$emit('upload-reminder', ris.data)
        }, 2500)
      }
    }
  }
}
</script>

<style scoped>
.active{
    display: inline;
  }
  .no-active{
    display: none;
  }
</style>