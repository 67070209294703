<template>
  <div>
     <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <p><b>Istruzioni: </b>{{ item.instructions }}</p>
    <b-form-file
      v-model="file"
      multiple
      :placeholder="`Trascina o carica ${item.label} (allega 1 o + file ${item.mimetypes.join(', ')})`"
      drop-placeholder="Trascina qui..."
      :accept="item.mimetypes.join(', ')"
      :disabled="false"      
    >
      <template slot="file-name" slot-scope="{ names }">
        <b-badge variant="dark" v-if="names.length === 1">{{ names[0] }}</b-badge>
        <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
          Caricati {{ names.length }} file
        </b-badge>
      </template>
    </b-form-file>
    <card class="card-plain" v-if="file.length > 0">
      <template slot="header">
        <h4 class="card-title">Lista dei file</h4>
        <p class="card-category">Puoi visualizzare la lista dei file in questa sezione collassabile</p>
      </template>
      <el-collapse>
        <el-collapse-item title="Lista dei File" name="1">
          <div>
            <div id="preview" v-for="(file, index) in urls" :key="index">
              <div class="wrapper_custom">
                <span class="close" @click="deleteCard(index, file)">&times;</span>
                <!-- <img :src="file.url" /> -->
                <span>{{ file.name }}</span>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </card>
    <fg-input label="" style="margin-top:15px">
        <div >
          <l-checkbox v-model="nonRilevante">Non rilevante</l-checkbox>
        </div>
    </fg-input>
    <div style="padding:20px;"></div>
    <div class="row custom_error" v-if="error">
      <p style="color:red;">{{ error }} </p>
    </div>
    <div class="row custom_success" v-if="success">
      <p style="color:green;">{{ item.label }} caricato con successo!</p>
    </div>
    
    <l-button type="primary" style="margin-top: 25px;" :disabled="file.length === 0" wide @click="upload_files()">Carica File</l-button>&nbsp;

    <l-button type="primary" style="margin-top: 25px;" wide :class="{ 'active': isActive, 'no-active':!isActive }" @click="saveNonRilevante()">Applica</l-button>&nbsp;

  </div>
</template>

<script>
import Vue from 'vue'
import { FormFilePlugin } from 'bootstrap-vue'
Vue.use(FormFilePlugin)
import { BadgePlugin } from 'bootstrap-vue'
Vue.use(BadgePlugin)
import { Collapse, CollapseItem } from 'element-ui'
import ReminderService from 'src/services/ReminderService'
import {
    Checkbox as LCheckbox,
  } from 'src/components/index'

export default {
  props: ['item', 'token'],
  data() {
    return {
      hasLoaded: true,
      error: null,
      success: false,
      file: [],
      urls: [],
      nonRilevante:false,
      isActive:false
    }
  },
  components: {
    FormFilePlugin,
    BadgePlugin,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    LCheckbox
  },
  watch:{
    nonRilevante: function(value){
      this.isActive = value
    },
    file:function(value){   
      var files = value;
      var urls = []
      for (let i = 0; i<files.length; i++) {
        const file = files[i];
        urls.push({ url: URL.createObjectURL(file), name: file.name });
      }
      this.urls = urls
    }
  },
  methods: {    
    async saveNonRilevante(){
      var ris = null
      try{
        const data = {
          "order": this.item.order 
        }
        ris = await ReminderService.uploadItemsNonRilevante(data, this.token)

      } catch(err) {
        console.log('err', err)
        this.error = 'Errore durante l\'aggiornamtento di ' + this.item.label
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.success = false
          this.error = null
          this.$eventHub.$emit('upload-reminder', ris.data)
        }, 2500)
      }
    },
    deleteCard(index, file) {
      console.log('Elimina file: ', file)
      this.file.splice(index, 1)
      this.urls = this.urls.filter(el => el.name !== file.name)
    },
    async upload_files() {
      var ris = null
      try {
        this.hasLoaded = false
        var formData = new FormData();
        let order = this.item.order
        console.log("length",this.file.length)
        for (let i = 0; i<this.file.length; i++) {
          formData.append(order, this.file[i]);
        }
        //TODO  passare non rilevante nel campo dell'item, se esiste cmq
        // il campo devo inserirlo
        ris = await ReminderService.uploadItems(formData, this.token)
        console.log('ris', ris)
        this.urls = []
        this.file = []
        this.success = true
      } catch(err) {
        console.log('err', err)
        this.error = 'Errore durante il caricamento di ' + this.item.label
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.success = false
          this.error = null
          this.$eventHub.$emit('upload-reminder', ris.data)
        }, 2500)
      }
    }
  }
}
</script>

<style scoped>
  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #preview div img {
    max-width: 90%;
    max-height: 500px;
  }
  .wrapper_custom {
    position: relative;
    display: inline-block;
    margin-top: 25px;
  }
  .close:before {
    /* content: '✕'; */
  }
  .close {
    color: red;
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
  }
  .active{
    display: inline;
  }
  .no-active{
    display: none;
  }
</style>